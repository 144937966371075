





































































































import {Component, Ref, Vue} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import NoRobot from "@/components/global/NoRobotV2.vue";
import ViewDesign from "@/components/global/ViewDesign.vue"

@Component({
    components: {PersistentDialog, NoRobot, ViewDesign},
})
export default class Register extends Vue {
    @Ref()
    private recapture!:NoRobot;
    private registerSuccessText: string = this.$tc('register.new.success');
    private registerSuccess: boolean = false;
    private registerErrorText: string = this.$tc('register.new.failed');
    private showRegisterError: boolean = false;
    private isSend: boolean = false;
    private valid: boolean = true;
    private email: string = "";
    private firstname: string = "";
    private lastname: string = "";
    private password: string = "";
    private confirmPassword: string = "";
    private emailRules = [
        v => !!v || this.$tc('register.new.validation.emailRequired'),
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$tc('register.new.validation.emailValid'),
    ];
    private showPasswords: boolean = false;
    private passWordRules = {
        required: value => !!value || this.$tc('register.new.validation.passwordRequired'),
        min: v => (v && v.length >= 8) || this.$tc('register.new.validation.passwordMinLength'),
    };

    mounted() {
        this.valid = false;
    }

    private async validate() {
        //@ts-ignore
        if (this.$refs.registerForm.validate()) {
            const result = await this.recapture.validate();

            if(result) {
                await this.$webApi.register(this.email, this.password, this.firstname, this.lastname, this.$store.getters.lang).then(result => {
                    if (result) {
                        this.registerSuccess = this.isSend = true;
                    } else {
                        this.showRegisterError = true;
                    }
                }).catch((e) => {
                    if (e.response) {
                        if (e.response.status == 403) {
                            this.$router.push("login");
                        }
                        //401 user password empty
                        //402 registerUser emailExists
                        //403 user emailExists
                        //404 email send failed
                        //405 something went wrong
                        //alert(e.response.status);
                    }
                    this.showRegisterError = true;
                });
            } else {
                await this.recapture.reset();
            }

            if(this.showRegisterError) {
                await this.recapture.reset();
            }
        }
    }
}

